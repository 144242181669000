<template>
	<div class="userActionLog" v-cloak>
		<div class="search-box">
			
		</div>
		<div class="table-list">
			<el-table :data="tableData" stripe border style="width: 100%" >
				<el-table-column prop="date" label="日期" width="180"></el-table-column>
				<el-table-column prop="name" label="姓名" width="180"></el-table-column>
				<el-table-column prop="address" label="地址"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import userActionLog from './userActionLog.js';

export default userActionLog;
</script>

<style scoped lang="scss">
@import './userActionLog.scss';
</style>

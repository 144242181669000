import data from "./userActionLogData.js"
export default {
  name: "userActionLog",
  data,
  computed:{

  }, 
  created() {
	 this.$store.state.title = this.title;   
  },
  mounted() {

  },
  methods:{

  },
 
  beforeDestroy(){

  },
  watch:{

  }
}

